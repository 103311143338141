import React, { useContext } from "react"

import { GlobalDispatchContext } from "../../context/GlobalContextProvider"
import searchIcon from "../../images/search-icon.svg"

export const SearchBar = () => {
  const dispatch = useContext(GlobalDispatchContext)
  return (
    <div className="search-bar">
      <input type="text" className="search-bar__input" onChange={(e) => dispatch({ type: "SET_SEARCH_VALUE", payload: e.target.value })} />
      <img src={searchIcon} alt="search" className="search-bar__input-icon" />
    </div>
  )
}
