import React, { useContext } from "react"

import { GlobalStateContext } from "../../context/GlobalContextProvider"
import { translateContent } from "../../utils/translate-content"
import { AllProducts } from "../AllProducts/AllProducts"
import { ProductTags } from "../ProductTags/ProductTags"
import { SearchBar } from "../SearchBar/SearchBar"

export const Products = ({ queryData }) => {
  const { language } = useContext(GlobalStateContext)

  const {
    wpPage: {
      productPageCustomFields: { productPageDescription, productPageDescriptionPl, productPageTitlePl },
      title
    }
  } = queryData
  const { allWpTag } = queryData
  const { allWpProduct } = queryData

  return (
    <div className="content-wrapper products">
      <h1 className="content-wrapper__header-product">{translateContent(language, title, productPageTitlePl)}</h1>
      <p>{translateContent(language, productPageDescription, productPageDescriptionPl)}</p>
      <SearchBar />
      <ProductTags tags={allWpTag} />
      <AllProducts data={allWpProduct} />
    </div>
  )
}
