import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useContext, useEffect } from "react"

import { GlobalStateContext } from "../../context/GlobalContextProvider"
import { GlobalDispatchContext } from "../../context/GlobalContextProvider"

export const AllProducts = ({ data }) => {
  const { nodes } = data
  const { language, globalTag, globalProducts, searchValue } = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  useEffect(() => {
    let filteredProducts = nodes

    if (!searchValue) {
      filteredProducts = nodes
    }

    if (globalTag && filteredProducts) {
      let filteredValues = []
      filteredProducts.forEach((element) => {
        element.tags.nodes.forEach((tag) => {
          if (tag.id === globalTag) {
            filteredValues.push(element)
          }
        })
      })
      filteredProducts = filteredValues
    }

    if (globalTag === "all-products") {
      filteredProducts = nodes
    }

    let results = filteredProducts.filter((product) => {
      const { productTitle, productStyle } = product.product
      const searchValueLowerCase = searchValue.toLowerCase().trim()
      let productTitleLowerCase = productTitle.toLowerCase()
      let productStyleLowerCase = productStyle.toLowerCase()

      return productTitleLowerCase.includes(searchValueLowerCase) || productStyleLowerCase.includes(searchValueLowerCase)
    })
    dispatch({ type: "SET_GLOBAL_PRODUCTS", payload: results })
  }, [globalTag, searchValue])

  useEffect(() => {
    dispatch({ type: "SET_GLOBAL_PRODUCTS", payload: nodes })
  }, [])

  return (
    <div className="all-products">
      {globalProducts.length > 0 &&
        globalProducts.map((item) => {
          const imageData = getImage(item.product.productImage.localFile)
          return (
            <Link key={item.id} to={`/${item.product.productType}/${item.slug}`} className="product">
              <GatsbyImage image={imageData} className="product__image" alt={`moon lark-${item.slug}`} />
              <h2 className="product__header">{item.product.productTitle}</h2>
              <h3 className="product__sub-header">{item.product.productStyle}</h3>
            </Link>
          )
        })}
      {globalProducts.length === 0 && (
        <div className="all-products__empty">
          <h1>{language === "en" ? "No results found" : "Jeszcze tego nie uwarzyliśmy"}</h1>
        </div>
      )}
    </div>
  )
}
