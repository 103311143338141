import React, { useContext } from "react"
import { graphql } from "gatsby"

import { BackgroundLayout } from "../components/BackgroundLayout/BackgroundLayout"
import { Footer } from "../components/Footer/Footer"
import { Layout } from "../components/Layout/Layout"
import { Products } from "../components/Products/Products"
import { SEO } from "../components/SEO/Seo"
import { GlobalStateContext } from "../context/GlobalContextProvider"

import "../sass/main.scss"

const IndexPage = ({ data }) => {
  return (
    <main>
      <Layout>
        <BackgroundLayout>
          <Products queryData={data} />
          <Footer />
        </BackgroundLayout>
      </Layout>
    </main>
  )
}

export default IndexPage

export const Head = ({ data }) => {
  const { language } = useContext(GlobalStateContext)
  return (
    <SEO
      title={language === "pl" ? data.wpPage.productPageCustomFields.productPageTitlePl : data.wpPage.title}
      pathname={`/${data.wpPage.slug}`}
    />
  )
}

export const query = graphql`
  query ($slug: String) {
    wpPage(slug: { eq: $slug }) {
      slug
      title
      productPageCustomFields {
        productPageTitlePl
        productPageDescription
        productPageDescriptionPl
      }
    }
    allWpTag(filter: { description: { eq: $slug } }) {
      nodes {
        name
        id
      }
    }

    allWpProduct(sort: { order: DESC, fields: date }, filter: { product: { productType: { eq: $slug } } }) {
      nodes {
        id
        slug
        product {
          productTitle
          productStyle
          productType
          productImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        tags {
          nodes {
            name
            id
          }
        }
      }
    }
  }
`
