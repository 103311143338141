import React, { useContext } from "react"

import { GlobalStateContext } from "../../context/GlobalContextProvider"
import { GlobalDispatchContext } from "../../context/GlobalContextProvider"

export const ProductTags = ({ tags }) => {
  const { language, globalTag } = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  return (
    <div className="product-tags">
      <div className="product-tags__container">
        <div
          className={globalTag === "all-products" ? "product-tags__item--active" : "product-tags__item"}
          onClick={() => dispatch({ type: "SET_GLOBAL_TAG", payload: "all-products" })}
        >
          <span>{language === "en" ? "All Products" : "Wszystkie"}</span>
        </div>
        {tags.nodes.map((tag) => (
          <div
            key={tag.id}
            className={globalTag === tag.id ? "product-tags__item--active" : "product-tags__item"}
            onClick={() => dispatch({ type: "SET_GLOBAL_TAG", payload: tag.id })}
          >
            <span>{tag.name.split("-")[0]}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
